import { last } from "lodash";
import React, { useCallback, useMemo, useEffect, useState } from "react";
import {
  Dialog,
  Button,
  DateRangePicker,
  Select,
  useToasts,
  GroupBy,
  ReportExportFormat,
  DialogRadioGroup,
  CheckboxField,
  styled,
  RadioGroup,
} from "@puzzle/ui";
import { useActiveCompany } from "components/companies";
import { getViewOptions } from "components/companies/common";
import {
  useLedgerReportDownloadByPeriodLazyQuery,
  useLedgerExcelReportDownloadByPeriodLazyQuery,
  useLedgerExcelMultiReportDownloadByPeriodLazyQuery,
} from "../graphql.generated";
import { FilterState, StickyFilters, useReportFilter } from "../useReportFilter";
import {
  DynamicReportType,
  LedgerReportColumnBy,
  LedgerReportFilterInput,
  LedgerReportLevel,
  LedgerView,
  SummaryColumn,
} from "graphql/types";
import { LastYearByYear, getCurrentReportPreset } from "../reportPresets";
import { CalendarView, today } from "@puzzle/utils";
import { downloadFileFromPath } from "components/common/files/utils";
import { MonthlyExpensesExceededBannerInline } from "components/featureGate/MonthlyExpensesExceededBanner";
import { useExpensesExceededFeatureGate } from "components/featureGate/useExpensesExceededFeatureGate";
import useReportTimePeriods from "../useReportTimePeriods";
import { getLedgerReportLevels, StyledToolbar } from "../common";
import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { ClassificationsFilter } from "components/dashboard/Report/Filters/ClassificationsFilter";
import { FilterTags } from "components/dashboard/Report/Filters/FilterTags";
import {
  ReportPeriodFilters,
  buildClassificationOptions,
} from "components/dashboard/Report/Filters/sharedReportFilters";
import useClassifications from "components/common/hooks/useClassifications";
import {
  getClassificationColumns,
  getColumnBy,
  isGroupedByPeriod,
  getReportIntervals,
} from "components/dashboard/Report/reportClassificationUtils";
import { Box, S } from "ve";
import { inputBlock, inputLabel } from "../Reports.css";

interface Props {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  type?: DynamicReportType;
  isReportPackage?: boolean;
  filter?: LedgerReportFilterInput;
  stickyFilters?: StickyFilters;
}

const CheckBoxWrapper = styled("div", {
  marginLeft: "$2",
  display: "flex",
  gap: "$1",
  flexDirection: "column",
});

const exportOptions = [
  {
    value: ReportExportFormat.CSV,
    label: "CSV",
    description: "",
  },
  {
    value: ReportExportFormat.PDF,
    label: "PDF",
    description: "",
  },
];
const disabledCss = { opacity: 0.3, pointerEvents: "none" };

export const ReportExportModal = ({
  open,
  onConfirm,
  onCancel,
  title,
  type,
  isReportPackage,
  stickyFilters,
}: Props) => {
  const { toast } = useToasts();
  const { company, timeZone } = useActiveCompany<true>();
  const { filters, setFilters } = useReportFilter(stickyFilters);
  const { anyDatesExpenseGated } = useExpensesExceededFeatureGate();
  const isExpensesExceeded = anyDatesExpenseGated([filters.start, filters.end]);
  const { classificationsData } = useClassifications({});
  const classesM1Enabled = isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsM1);

  const [summaryColumn, setSummaryColumn] = useState(
    SummaryColumn.FinalPeriodsAbsoluteAndPercentChange
  );

  const reportExportOptions = useMemo(
    () =>
      type !== DynamicReportType.TrialBalance
        ? [
            ...exportOptions,
            {
              value: ReportExportFormat.EXCEL,
              label: "Excel",
              description: "",
            },
          ]
        : exportOptions,
    [type]
  );

  const [getReportByPeriod] = useLedgerReportDownloadByPeriodLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      downloadFileFromPath(data.ledgerReportDownloadByPeriod.file.downloadInfo.signedUrl);

      if (!type) {
        throw new Error("report type required for individual report download");
      }
      Analytics.reportDownloaded({
        reportId: data.ledgerReportDownloadByPeriod.file?.id,
        reportType: type,
        reportFormat: filters.format,
      });
    },
    onError: () => {
      toast({
        status: "error",
        message:
          "Your report failed to generate. Please try again. If the issue persists, please narrow your query or contact support.",
      });
    },
  });

  const [getExcelReportByPeriod] = useLedgerExcelReportDownloadByPeriodLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      downloadFileFromPath(data.ledgerExcelReportDownloadByPeriod.file.downloadInfo.signedUrl);

      if (!type) {
        throw new Error("report type required for individual report download");
      }

      Analytics.reportDownloaded({
        reportId: data.ledgerExcelReportDownloadByPeriod.file?.id,
        reportType: type,
        reportFormat: filters.format,
      });
    },
    onError: () => {
      toast({
        status: "error",
        message:
          "Your report failed to generate. Please try again. If the issue persists, please narrow your query or contact support.",
      });
    },
  });

  const [getExcelMultiReportByPeriod] = useLedgerExcelMultiReportDownloadByPeriodLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      downloadFileFromPath(data.ledgerExcelMultiReportDownloadByPeriod.file.downloadInfo.signedUrl);

      Analytics.reportDownloaded({
        reportId: data.ledgerExcelMultiReportDownloadByPeriod.file?.id,
        reportType: "FinancialReportPackage",
        reportFormat: filters.format,
      });
    },
    onError: () => {
      toast({
        status: "error",
        message:
          "Your report failed to generate. Please try again. If the issue persists, please narrow your query or contact support.",
      });
    },
  });

  const input = useMemo(() => ({ companyId: company.id, type }), [company, type]);
  const periods = useReportTimePeriods({
    start: filters.start,
    end: filters.end,
    groupBy: isGroupedByPeriod(filters.groupBy)
      ? (filters.groupBy as GroupBy)
      : filters.preset?.groupBy,
  });

  const shouldShowExportOptions =
    isGroupedByPeriod(filters.groupBy) && filters.format === ReportExportFormat.EXCEL;
  const exportOptionsBlockCss = shouldShowExportOptions ? {} : disabledCss;

  useEffect(() => {
    if (title === "Trial Balance") {
      const [start, end] = LastYearByYear.range!();
      setFilters({
        preset: LastYearByYear,
        groupBy: GroupBy.Year,
        start: start,
        end: end,
        format: ReportExportFormat.CSV,
      });
    }
  }, [setFilters, title]);

  const maxDate = useMemo(() => {
    const date = today(timeZone);
    return filters.groupBy !== GroupBy.Year ? date : date.add({ years: 1 });
  }, [timeZone, filters.groupBy]);

  const getReport = useCallback(() => {
    const columnBy = getColumnBy(filters.groupBy);
    const reportClassFilter = columnBy === LedgerReportColumnBy.Interval ? filters.filter : {};

    const intervals = getReportIntervals(periods, columnBy, type);
    if (filters.format === ReportExportFormat.PDF) {
      const { filter } = filters;
      let filtersParam = "";

      if (classesM1Enabled && type === DynamicReportType.ProfitAndLoss && filter) {
        filtersParam = `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
      }
      const calendarView = isGroupedByPeriod(filters.groupBy)
        ? filters.groupBy
        : filters.preset?.view ?? CalendarView.Month;

      window.open(
        `/print?basis=${filters.accountingBasis}&type=${type}&start=${periods[0].start}&end=${
          last(periods)?.end
        }&groupBy=${filters.groupBy}&calendarView=${calendarView}${filtersParam}`
      );
    } else if (isReportPackage) {
      getExcelMultiReportByPeriod({
        variables: {
          input: {
            companyId: input.companyId,
            config: {
              filter: reportClassFilter,
              columnBy: columnBy,
              columns: {
                segments: getClassificationColumns(
                  filters.filter,
                  columnBy,
                  filters.groupBy,
                  classificationsData
                ),
              },
              intervals: { byPeriod: intervals, byDate: [] },
            },
            view: filters.accountingBasis,
            level: filters.level,
            includeAccountNumbers: filters.includeAccountNumbers,
            decimalTruncate: filters.decimalTruncate,
            summaryColumn: shouldShowExportOptions ? summaryColumn : undefined,
            reports:
              filters.accountingBasis === LedgerView.Accrual
                ? // CAR shouldn't be generated on accrual basis
                  [DynamicReportType.BalanceSheet, DynamicReportType.ProfitAndLoss]
                : [
                    DynamicReportType.BalanceSheet,
                    DynamicReportType.ProfitAndLoss,
                    DynamicReportType.CashActivityReport,
                  ],
          },
        },
      });
    } else if (filters.format === ReportExportFormat.EXCEL) {
      const type = input.type;
      if (!type) {
        throw new Error("report type required for individual report download");
      }
      getExcelReportByPeriod({
        variables: {
          input: {
            ...input,
            type: type,
            config: {
              filter: reportClassFilter,
              columnBy: columnBy,
              columns: {
                segments: getClassificationColumns(
                  filters.filter,
                  columnBy,
                  filters.groupBy,
                  classificationsData
                ),
              },
              intervals: { byPeriod: intervals, byDate: [] },
            },
            view: filters.accountingBasis,
            level: filters.level,
            includeAccountNumbers: filters.includeAccountNumbers,
            decimalTruncate: filters.decimalTruncate,
            summaryColumn: shouldShowExportOptions ? summaryColumn : undefined,
          },
        },
      });
    } else {
      const type = input.type;
      if (!type) {
        throw new Error("report type required for individual report download");
      }
      getReportByPeriod({
        variables: {
          input: {
            ...input,
            type: type,
            config: {
              filter: reportClassFilter,
              columnBy: columnBy,
              columns: {
                segments: getClassificationColumns(
                  filters.filter,
                  columnBy,
                  filters.groupBy,
                  classificationsData
                ),
              },
              intervals: { byPeriod: intervals, byDate: [] },
            },
            view: filters.accountingBasis,
            level: filters.level,
          },
        },
      });
    }
  }, [
    periods,
    filters,
    type,
    getReportByPeriod,
    input,
    getExcelReportByPeriod,
    getExcelMultiReportByPeriod,
    isReportPackage,
    summaryColumn,
    classificationsData,
    classesM1Enabled,
    shouldShowExportOptions,
  ]);

  const enableClassesAndDepts =
    isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsM1) &&
    type === DynamicReportType.ProfitAndLoss;

  const viewByOptions = () => {
    if (enableClassesAndDepts) {
      return [...ReportPeriodFilters, ...buildClassificationOptions(classificationsData, true)];
    }
    if (type === DynamicReportType.BalanceSheet) {
      return ReportPeriodFilters.filter((f) => f.value !== "total");
    }
    return ReportPeriodFilters;
  };

  const dependentPresets = getCurrentReportPreset(filters.groupBy);
  const customPresetObj = dependentPresets[dependentPresets.length - 1];
  const curPreset = dependentPresets.find((p) => p.key === filters?.preset?.key) || customPresetObj;

  return (
    <Dialog open={open} size="small" onOpenChange={onCancel}>
      <Dialog.Title>
        Export {title} {!isReportPackage && "report"}
      </Dialog.Title>
      <Dialog.Body css={{ padding: 0 }}>
        <div className={inputBlock}>
          <div className={inputLabel}>View by</div>
          <StyledToolbar>
            <Select
              value={filters.groupBy}
              onSelectionChange={(value) => {
                const newFilter: Partial<FilterState> = {
                  groupBy: value,
                };
                const filterDoesNotMatchGroupBy =
                  !isGroupedByPeriod(value) && filters.filter?.segments?.reportingClassId !== value;
                if (filterDoesNotMatchGroupBy) {
                  newFilter.filter = {};
                }
                setFilters(newFilter);
              }}
              options={viewByOptions()}
              size={"mini"}
            />
          </StyledToolbar>
        </div>
        <div className={inputBlock}>
          <div className={inputLabel}>Set period range</div>
          <StyledToolbar>
            <DateRangePicker
              inferCustomPresets={false}
              value={[filters.start, filters.end]}
              onChange={([s, e], preset) => {
                setFilters({ start: s, end: e, preset });
              }}
              maxDate={maxDate}
              view={filters.preset?.view ?? CalendarView.Month}
              groupBy={
                isGroupedByPeriod(filters.groupBy) ? (filters.groupBy as GroupBy) : GroupBy.Month
              }
              presets={dependentPresets}
              preset={curPreset}
              showLabel={false}
              dark={false}
              showInputs
            />
          </StyledToolbar>
        </div>
        {enableClassesAndDepts && (
          <div className={inputBlock}>
            <div className={inputLabel}>Classifications</div>
            <StyledToolbar>
              <ClassificationsFilter
                key="classifications-filter"
                onChange={setFilters}
                filter={filters.filter}
                groupBy={filters.groupBy}
                placeholderText="Select classes"
              />
            </StyledToolbar>
            <Box css={{ marginTop: S["1h"], flexBasis: "100%" }}>
              <FilterTags filter={filters.filter} onRemove={setFilters} />
            </Box>
          </div>
        )}
        {!title.includes("Cash Activity") && (
          <div className={inputBlock}>
            <div className={inputLabel}>Accounting basis</div>
            <div>
              <Select
                css={{ cursor: "pointer" }}
                options={getViewOptions(company, " basis")}
                value={filters.accountingBasis}
                onSelectionChange={(view) => setFilters({ accountingBasis: view as LedgerView })}
                size="small"
              />
            </div>
          </div>
        )}
        {type !== DynamicReportType.TrialBalance && (
          <div className={inputBlock}>
            <div className={inputLabel}>Report level</div>
            <div>
              <Select
                css={{ cursor: "pointer" }}
                options={getLedgerReportLevels()}
                value={filters.level}
                onSelectionChange={(level) => setFilters({ level: level as LedgerReportLevel })}
                size="small"
              />
            </div>
          </div>
        )}
        {!isReportPackage && (
          <div className={inputBlock}>
            <div className={inputLabel}>Export format</div>
            <DialogRadioGroup
              aria-label="format"
              value={filters.format}
              onValueChange={(format) => setFilters({ format: format as ReportExportFormat })}
              options={reportExportOptions}
            />
          </div>
        )}

        <div className={inputBlock} style={exportOptionsBlockCss}>
          <div className={inputLabel}>Export options</div>
          <CheckBoxWrapper>
            <CheckboxField
              label="Include account numbers"
              checked={
                filters.includeAccountNumbers && filters.level === LedgerReportLevel.Detailed
              }
              onCheckedChange={(includeAccountNumbers) => {
                setFilters({ includeAccountNumbers });
              }}
              disabled={
                filters.level === LedgerReportLevel.Compact ||
                filters.level === LedgerReportLevel.Summary
              }
            />
            <CheckboxField
              label="Truncate decimals to whole dollars"
              checked={filters.decimalTruncate}
              onCheckedChange={(decimalTruncate) => {
                setFilters({ decimalTruncate });
              }}
            />
            {type !== DynamicReportType.BalanceSheet && (
              <>
                <Box
                  css={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: S["3"],
                  }}
                >
                  Summary Column
                </Box>
                <RadioGroup
                  aria-label="ruleType"
                  value={summaryColumn}
                  onValueChange={(value: SummaryColumn) => setSummaryColumn(value)}
                  options={[
                    {
                      value: SummaryColumn.FinalPeriodsAbsoluteAndPercentChange,
                      label: "Absolute and Percent Change",
                    },
                    {
                      value: SummaryColumn.AllColumnSum,
                      label: "Sum Total",
                    },
                  ]}
                />
              </>
            )}
          </CheckBoxWrapper>
        </div>
        {isExpensesExceeded && (
          <Box css={{ paddingLeft: S["3"], paddingRight: S["3"], paddingTop: S["3"] }}>
            <MonthlyExpensesExceededBannerInline subtext="download reports for the selected period" />
          </Box>
        )}
      </Dialog.Body>
      <Dialog.Footer align="right">
        <Dialog.Actions>
          <Dialog.Close asChild>
            <Button variant="secondary" onClick={onCancel} autoFocus>
              Cancel
            </Button>
          </Dialog.Close>
          <Button
            variant="primary"
            onClick={() => {
              const message =
                filters.format === ReportExportFormat.PDF
                  ? "We are generating your report. Large reports may take some time but will open in new window once complete."
                  : "We are generating your report. Large reports may take some time but will export once complete.";
              toast({
                status: null,
                message,
              });
              Analytics.reportModalExportClicked({
                reportType: type || "FinancialReportPackage",
                startDate: filters.start.toString(),
                endDate: filters.end.toString(),
                reportLevel: filters.level,
              });
              getReport();
              onConfirm();
            }}
            disabled={isExpensesExceeded}
          >
            Export
          </Button>
        </Dialog.Actions>
      </Dialog.Footer>
    </Dialog>
  );
};
